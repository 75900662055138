export const menus = {
  wallet: 'Wallet',
  notice: 'Notice',
  helpCenter: 'Help Center',
  serviceIntro: 'About us',
  userGuide: 'User Guide',
  faq: 'FAQ',
  personalCS: '1:1 Inquiry',
  event: 'Event',
  eventList: 'Event List',
  dailyCheck: 'Check-in',
  creatorFund: 'Creator Fund',
  policy: 'Policy',
  terms: 'Terms of Service ',
  privatePolicy: 'Privacy Policy',
  familySite: 'Family Site',
  theMoonLabs: 'The Moon Labs',
  leisureMeta: 'Leisure Meta',
}
