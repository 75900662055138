import type { ScopeCodeType } from '@store/post/type'

/**
 * 포스트 발행, 수정
 */
export const postCreate = {
  type: {
    normal: 'General Post',
    card: 'Card-type Post',
    vod: 'Video Post (under 500MB)',
  },
  typeDesc: {
    normal: 'A post that you can arrange photos and texts freely.',
    card: 'A post that photos can be viewed in a slide format like cards.',
    vod: 'A post that you can upload videos to be played.',
  },
  createAvailableCount:
    'The number of post you can write in a week at [Creator’s Content]. (Left posts/Max posts)',
  hashtagMessage: 'Try hashtags. Your post will be seen by more people.',
  placeholder: {
    public: 'Public',
    tempSave: 'Load Draft',
    category: 'Select Category',
    title: 'Write a title',
    content: 'Write a content. (Mandatory)',
    hashtag: '#Input_Hashtag',
    edit: {
      requireCategory: 'Please choose the location of your post',
      creatorContents: {
        p18: 'Hello, Web3 Creator,',
        p15: 'Please upload attractive and unique content.<br />You can upload artwork such as Drawing, Music, Video, Writing, etc that you created and communicate with your fans.',
        p12: '-Posts which do not fit the nature of the board can be restricted by accumulation of reports or by judgment of the administrator.<br />-In the case of duplicated or stolen posts, it is strictly regulated and may be permanently restricted from use.',
      },
      free: "-Posts that violate the service's operating policy, such as spam, or illegal, may be subject to sanctions.",
      novaChallenge:
        'Tell us your story!<br /><br />Please write a thoughtful post on the topic presented by I LIKE LM within a set timeframe.<br />(The challenge topic will be announced periodically and you can check the I LIKE LM pinned post in your feed).<br /><br />-Posting non-challenge-related posts in the NOVA Challenge category may result in unnoticed removal.',
    },
  },
  button: {
    tempSave: 'Save Draft',
    preview: 'Preview',
    save: 'Upload',
    confirm: 'Confirm',
  },
  postScope: {
    PUBL: 'Public',
    PRIV: 'Only me',
    // PAID: '유료(en)',
  } as { [Property in ScopeCodeType]: string },
  toastMessage: {
    tempSave: 'It has been saved temporarily.',
    noLinkSelect: 'Please select the text to link.',
    havingLink: 'Link has been already designated.',
    validateCategory: 'Please select a category.',
    validateTitle: 'Please write more than 2 characters for the title.',
    validateContents: 'Please write more than 15 characters for the contents.',
    validateHashtagCharacter:
      'It is available to input Korean, English and numbers.',
    validateHashtagCheckByte: 'It is available to input maximum 30 characters.',
    validateHashtagCount: 'It is available to input maximum 5 tags.',
    validateHashtagDuplicate: 'Duplicated tag exists.',
    imgError2005: 'This extension cannot be registered.',
    imgError: 'The image upload failed. Please try again later.',
    errors: {
      2011: 'Premium post cannot be issue. Please open a premium first.',
      2015: 'The number of hashtags has exceeded. Please try again.',
      2016: 'The number of image files has exceeded. Please try again.',
      2017: 'The number of subject characters has exceeded. Please try again.',
      2018: 'The number of hashtag characters has exceeded. Please try again.',
      2019: 'The temporary image file could not be found. Please try again.',
      2036: 'You do not have permission to publish on this category.',
      8048: 'The same activity cannot be repeated within a certain amount of time.',
      8049: "You've exceeded the maximum number of posts you can upload in a week.",
      default:
        'An error occurred while processing your request. Please try again later.',
    },
    validateLinkDuplicate: 'Duplicated link exists.',
    availableLink: 'Available links.',
    nolastFileDelete: "The last file can't be deleted.",
    createPostSuccess: '"{postTitle}" post has been published.',
    editPostSuccess: '"{postTitle}" post has been edited',
    createPostUnavailable: {
      2011: 'The category is unknown.',
      2036: 'You do not have permission to publish on this category.',
    },
    videoFileSize: {
      over: 'You have exceeded the allowed file size. (500MB)',
      error: 'An error has occurred due to the file size exceeding the limit.',
      warning: 'Uploading files larger than 100MB may take some time.',
    },
  },
  modal: {
    tempTitle: 'Draft',
    previewTitle: 'Preview',
    dialogTitle: 'Confirm deletion',
    dialogContent:
      'If you close without saving, any content you entered will be lost. Do you want to continue and discard changes?',
  },
}
