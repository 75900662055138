/**
 * 크리에이터 등급
 */
export const creatorHome = {
  statistics: {
    menu: {
      views: '조회수',
      follows: '팔로워수',
      followUnit: '명',
      ranking: '랭킹',
      rankingUnit: '위',
      reward: '리워드',
      sharedContents: '공유된 콘텐츠',
      notice: '게시글',
      video: '영상',
      popularPosts: '인기 게시물',
      popularVideoPosts: '인기 영상',
    },
    popularPosts: {
      error: {
        empty: '조회 결과가 없습니다.',
        error: '조회 중 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
    popularVideoPosts: {
      error: {
        empty: '조회 결과가 없습니다.',
        error: '조회 중 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
  },
  posts: {
    filterType: {
      normal: '일반 포스트',
      card: '카드형 포스트',
      vod: '영상형 포스트',
    },
  },
}
