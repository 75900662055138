/**
 * 크리에이터 등급
 */
export const creatorHome = {
  statistics: {
    menu: {
      views: 'ยอดวิว',
      follows: 'ผู้ติดตาม',
      followUnit: '',
      ranking: 'อันดับ',
      rankingUnit: '',
      reward: 'รางวัล',
      sharedContents: 'เนื้อหาที่แชร์',
      notice: 'โพสต์',
      video: 'วิดีโอ',
      popularPosts: 'โพสต์ยอดนิยม',
      popularVideoPosts: 'โพสต์วิดีโอยอดนิยม',
    },
    popularPosts: {
      error: {
        empty: 'ไม่มีโพสต์ที่นี่',
        error: 'ไม่สามารถโหลดได้ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
      },
    },
    popularVideoPosts: {
      error: {
        empty: 'ไม่มีโพสต์ที่นี่',
        error: 'ไม่สามารถโหลดได้ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
      },
    },
  },
  posts: {
    filterType: {
      normal: 'โพสต์ทั่วไป',
      card: 'โพสต์แบบการ์ด',
      vod: 'โพสต์แบบวิดีโอ',
    },
  },
}
