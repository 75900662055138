export const onboarding = {
  content: {
    step01: {
      title: 'I LIKE LM에 오신 것을<br />환영합니다!',
      message:
        'I LIKE LM은 크리에이터와 팬덤이 서로 활동하고, <br />소통하며, 리워드를 받는 웹3 플랫폼입니다.',
    },
    step02: {
      title: 'I LIKE LM에선 누구나 <br />크리에이터가 될 수 있어요!',
      message:
        '자신만의 아이디어가 있다면 크리에이터로 활동하세요!<br />콘텐츠를 업로드하고, 반응을 얻는 만큼 리워드를 <br />받을 수 있어요.',
    },
    step03: {
      title: 'I LIKE LM에선<br />모든 활동이 리워드가 됩니다!',
      message:
        '좋아하는 크리에이터를 응원해보세요!<br />댓글과 좋아요 등 활동하는 만큼 리워드를 받을 수 있어요.',
    },
    step04: {
      title: '함께할수록<br />커뮤니티의 가치가 커져요!',
      message:
        '팬덤 커뮤니티를 키울수록<br />크리에이터가 성장할 수 있는 기회도 많아져요!',
    },
    step05: {
      title: '지금 바로 I LIKE LM에<br />참여해 보세요!',
      message:
        '창작과 소통이 리워드가 되는<br />새로운 웹3 플랫폼을 경험해보세요!',
    },
  },
  button: {
    again: '다시 보지않기',
    skip: '건너뛰기',
    start: '바로 시작하기',
  },
}
