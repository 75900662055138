/**
 * 권한 요청
 */
export const requestAppPermission = {
  requestModal: {
    description: {
      storage: {
        post: "There is no access permission for 'photo/gallery' to post. Please go to settings and allow access.",
        profileImage:
          "There is no access permission for 'photo/gallery' to change profile image. Please go to settings and allow access.",
        mainProfileImage:
          "There is no access permission for 'photo/gallery' to change main profile image. Please go to settings and allow access.",
        homeHeaderImage:
          "There is no access permission for 'photo/gallery' to change header image. Please go to settings and allow access.",
      },
    },
  },
}
