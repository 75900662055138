import { commonError } from './commonError'
import type { FundTabType } from '@components/NovaLayoutFundHeader/NovaLayoutFundHeader.types'

/**
 * 펀드
 */
export const fund = {
  page: {
    header: {
      tabs: {
        // comingSoon: 'เร็ว ๆ นี้',
        history: 'ประวัติการเงินทุน',
        gaudiFund: 'Gaudi Fund',
      } as { [Property in FundTabType]: string },
    },
  },
  project: {
    season: 'Web3 Creator Fund_{month} {year}',
    pick: 'เลือก',
    support: 'สนับสนุน',
    more: 'เพิ่มเติม',
    messages: {
      terminate: 'โครงการของเดือน ได้สิ้นสุดลงแล้ว',
      ready: 'เรากำลังเตรียมการสำหรับ โครงการของเดือน',
      empty: 'ไม่มี โครงการของเดือน',
      voteTally: 'เรากำลังตรวจสอบจำนวนการโหวตอยู่',
      error: commonError.network.message,
    },
  },
  histories: {
    fundNo: 'No.{fundNo}',
    creatorNickname: '{creatorNickname}',
    message: {
      empty: 'ไม่มีรายการประวัติการเงินทุน',
      error: commonError.network.message,
    },
  },
  start: {
    title: 'Web3 Creator Fund',
    prevProject: {
      fundNo: 'No.{fundNo} Web3 Creator Fund',
      title: 'โครงการของ {month} {year}',
      action: 'ดูรายละเอียดโครงการ',
    },
    nextProject: {
      title: 'ใครคือดาวรุ่งคนถัดไปของกองทุน?',
      period: 'ระยะเวลาการสมัคร:',
      action: 'สมัครโครงการ',
    },
  },
  end: {
    title: 'Web3 Creator Fund',
    subTitle: 'โครงการที่ส่งมาอยู่ระหว่างการตรวจสอบ',
  },
  projectDetail: {
    message: {
      error: commonError.network.message,
    },
  },
  voteDialog: {
    title: 'คุณต้องการโหวตให้ {creatorNickname} หรือไม่?',
    message: '*คุณสามารถโหวตได้เพียงครั้งเดียวต่อโครงการ',
    actions: {
      negative: 'ยกเลิก',
      positive: 'โหวต',
    },
  },
  voteRequest: {
    error: {
      8045: 'เกิดข้อผิดพลาดขณะประมวลผลการโหวตของคุณ กรุณาลองอีกครั้งในภายหลัง',
      8046: 'เกิดข้อผิดพลาดขณะประมวลผลการยกเลิกการโหวต กรุณาลองอีกครั้งในภายหลัง',
      8047: 'ไม่อยู่ในช่วงเวลาการโหวต',
      default: commonError.network.message,
    },
    success: 'การโหวตเสร็จสิ้นแล้ว',
  },
  gaudiFund: {
    gaudiheader: 'GAUDI FUND The Moon Labs X Gaudi Knowledge Association',
    gaudiFundImage: 'GAUDI FUND',
    fundMessage: {
      theMoonLabs: 'The Moon Labs X Gaudi Knowledge Association',
      theMoonLabsMessage:
        'The Moon Labs ได้ลงนามในข้อตกลงความร่วมมือด้านดิจิทัลระดับโลกอย่างเป็นทางการกับ Gaudi Knowledge Association และกำลังร่วมมือในธุรกิจดิจิทัล Web3 ที่เกี่ยวข้องกับ Gaudi',
      lmnova: 'GAUDI Fund โดย I LIKE LM',
      lmnovaMessage:
        'I LIKE LM จะสะสมกองทุน Gaudi ทุกเดือนเพื่อสนับสนุน Creator ที่สานต่อวิสัยทัศน์ของ Gaudi และขับเคลื่อนโครงการสร้างสรรค์ต่างๆ ผ่านกองทุน Gaudi, I LIKE LM จะจัดกิจกรรมที่หลากหลายไม่เพียงแต่สำหรับมืออาชีพและผู้ที่สนใจ Gaudi เท่านั้น แต่ยังรวมถึงคนรุ่นใหม่โดยเฉพาะนักเรียนรุ่นเยาว์ เพื่อแนะนำค่านิยมของ Gaudi เราหวังว่าหลายๆ คนจะได้สัมผัสวิสัยทัศน์ของ Gaudi ผ่าน I LIKE LM โครงการที่กำลังดำเนินอยู่จะถูกบันทึกเป็นเนื้อหาและอัปโหลดไปยัง I LIKE LM ดังนั้น กรุณาตรวจสอบใน Creator Home!',
      lmnovaGaudi: 'ไปที่ Creator Home ของ Gaudi Knowledge',
      plan: 'แผนการดำเนินงาน GAUDI Fund',
      planMessage: {
        planTitle1: {
          title: 'การจัดตั้งสถาบันวิจัยและการศึกษาเฉพาะทาง Gaudi',
          message1:
            'ขยายการเข้าถึงการศึกษาของ Gaudi Knowledge Association และปรับปรุงคุณภาพผ่านการนำเทคโนโลยีดิจิทัลมาใช้',
          message2:
            'พัฒนาหลักสูตรออนไลน์, การประชุมเชิงปฏิบัติการ, และโปรแกรมเกี่ยวกับวิธีการเฉพาะของ Gaudi และแปลงเป็น NFT',
          message3:
            'รวบรวมและจัดหมวดหมู่เกี่ยวกับอาคารของ Gaudi, ชีวประวัติ, และทรัพยากรที่ครอบคลุมเพื่อจัดตั้ง Gaudipedia',
        },
        planTitle2: {
          title: 'โครงการศึกษางานที่ไม่เสร็จของ Gaudi และการออกแบบ',
          message1:
            'ทำงานเกี่ยวกับการเรนเดอร์ดิจิทัลของโบสถ์ Colonia Güell ร่วมกับ Bea Frois และทีมผู้เชี่ยวชาญด้าน Gaudi',
          message2:
            'การศึกษาการดึงดูดโรงแรมและการเรนเดอร์ดิจิทัลโดยผู้เชี่ยวชาญด้าน Gaudi, Federico Luis',
          message3:
            'การศึกษาของ Misiones de Tanger โดยผู้เชี่ยวชาญด้าน Gaudi, David Afonso',
        },
        planTitle3: {
          title: 'การนำ Gaudi มาสู่คนรุ่นใหม่',
          message1:
            'นำโดยผู้เชี่ยวชาญด้าน Gaudi, Bethany Koby, การพัฒนาหลักสูตรและโปรแกรมการศึกษาเกี่ยวกับวิธีการของ Gaudi สำหรับกลุ่มเป้าหมายเยาวชนและนักเรียน',
        },
        planTitle4: {
          title: 'AI ของ Gaudi',
          message1:
            'การศึกษาจิตวิทยา Gaudi Graph โดยผู้เชี่ยวชาญด้าน Gaudi, Mariona Grau',
          message2:
            'วางรากฐานสำหรับการพัฒนา ‘Gaudi AI’ ผ่านการศึกษาทางโครงสร้างเกี่ยวกับจิตใจอัจฉริยะ',
        },
      },
    },
    roadmap: 'ROADMAP ของ GAUDI FUND',
  },
  comingSoon: {
    title: 'ประกาศการต่ออายุเงินทุน',
    message:
      'Web3 Creator Fund จะเปิดใหม่อีกครั้งหลังจากการต่ออายุด้วยการปรับปรุงเพื่อเสริมสร้างการเดินทางสร้างสรรค์ของ Creator บน I LIKE LM',
  },
}
