export const menus = {
  wallet: 'กระเป๋าสตางค์',
  notice: 'ประกาศ',
  helpCenter: 'ศูนย์ช่วยเหลือ',
  serviceIntro: 'เกี่ยวกับเรา',
  userGuide: 'คู่มือผู้ใช้',
  faq: 'คำถามที่พบบ่อย',
  personalCS: 'สอบถาม 1:1',
  event: 'กิจกรรม',
  eventList: 'รายการกิจกรรม',
  dailyCheck: 'การเช็คอิน',
  creatorFund: 'กองทุนผู้สร้าง',
  policy: 'นโยบาย',
  terms: 'เงื่อนไขการบริการ',
  privatePolicy: 'ข้อมูลส่วนบุคคล',
  familySite: 'เว็บไซต์ครอบครัว',
  theMoonLabs: 'The Moon Labs',
  leisureMeta: 'Leisure Meta',
}
