/**
 * 후원하기
 */
export const donateAction = {
  title: 'Donate',
  guide:
    'Donation brings great joy to creators and motivates them in their work. Send heartfelt encouragement to your favorite creator!',
  balance: 'Balance',
  donation: 'Donation',
  howAmount: 'How much would you like to donate?',
  wallet: 'my wallet',
  donationPlaceholder: 'Enter the donation.',
  cheer: 'Please send a encouraging message!',
  cheerPlaceholder: 'Please enter your message for creator.',
  success: '{donation}LM has been sent to "{creator}"',
  error: {
    network:
      'An error occurred while processing your request. Please try again later.',
    lackOfBalance: 'Your balance is insufficient.',
    codes: {
      2006: 'Necessary value missing',
      2028: 'Lack of Balance',
      2029: 'Transfer Error',
      2031: 'You cannot sponsor LM below the decimal point.',
      4006: 'The sender has not been KYC verified',
      4008: 'The sender has no wallet address',
      4010: 'The receiver is not a creator',
      4011: 'The receiver has not been KYC verified',
      4012: 'The receiver has no wallet address',
    },
  },
}
