import type { ScopeCodeType } from '@store/post/type'

/**
 * 포스트 발행, 수정
 */
export const postCreate = {
  type: {
    normal: 'โพสต์ทั่วไป',
    card: 'โพสต์แบบการ์ด',
    vod: 'โพสต์แบบวิดีโอ ("ต่ำกว่า 500MB)',
  },
  typeDesc: {
    normal: 'โพสต์ที่สามารถจัดวางรูปภาพและข้อความได้ตามอิสระ',
    card: 'โพสต์แบบการ์ดที่สามารถเลื่อนดูรูปภาพในรูปแบบการ์ด',
    vod: 'โพสต์แบบวิดีโอที่สามารถแนบไฟล์วิดีโอ',
  },
  createAvailableCount:
    'จำนวนโพสต์ที่สามารถเขียนในบอร์ด [เนื้อหาของผู้สร้าง] ในหนึ่งสัปดาห์ (จำนวนโพสต์ที่เหลือ/จำนวนโพสต์สูงสุด)',
  hashtagMessage:
    'แฮชแท็กที่คุณใช้จะทำให้โพสต์ของคุณถูกมองเห็นโดยผู้คนมากขึ้น!',
  placeholder: {
    public: 'เผยแพร่สาธารณะ',
    tempSave: 'เรียกดูการบันทึกชั่วคราว',
    category: 'เลือกบอร์ด',
    title: 'กรุณากรอกชื่อเรื่อง',
    content: 'กรุณากรอกเนื้อหา (จำเป็น)',
    hashtag: '#กรอกแฮชแท็ก',
    edit: {
      requireCategory: 'กรุณาเลือกบอร์ดก่อน',
      creatorContents: {
        p18: 'สวัสดีค่ะ/ครับ, ผู้สร้าง Web3',
        p15: 'โปรดแชร์เนื้อหาที่มีเสน่ห์และเป็นเอกลักษณ์ของคุณ คุณสามารถโพสต์เนื้อหาหลายประเภท เช่น รูปภาพ, เพลง, วิดีโอ, บทความที่คุณสร้างขึ้นและติดต่อสื่อสารกับแฟนๆ ได้อย่างอิสระ',
        p12: '-โพสต์ที่ไม่ตรงตามลักษณะของบอร์ดอาจถูกจำกัดการแสดงผลโดยไม่แจ้งล่วงหน้า ตามการตัดสินของผู้ดูแล<br />-โพสต์ที่ซ้ำกันหรือใช้เนื้อหาของผู้อื่นโดยไม่ได้รับอนุญาตจะถูกควบคุมอย่างเข้มงวดและอาจถูกจำกัดการใช้งานตลอดไป',
      },
      free: '-ข้อความที่ละเมิดนโยบายการดำเนินงานของบริการ เช่น การโพสต์ซ้ำ, การขโมย, การกระทำผิดกฎหมาย จะถูกควบคุมอย่างเข้มงวดและอาจถูกจำกัดการใช้งานตลอดไป',
      novaChallenge:
        'โปรดเล่าเรื่องของคุณ!<br /><br />โปรดเขียนข้อความอย่างตั้งใจตามหัวข้อที่ I LIKE LM กำหนดในระยะเวลาที่กำหนด (รอบของกิจกรรมจะประกาศเป็นระยะๆ และคุณสามารถตรวจสอบได้ที่โพสต์ที่ติดอยู่ด้านบนในฟีดของ I LIKE LM)<br />-หากเขียนโพสต์ที่ไม่เกี่ยวข้องกับความท้าทายในหมวดหมู่ Nova Challenge อาจถูกจำกัดการแสดงผลโดยไม่แจ้งล่วงหน้า',
    },
  },
  button: {
    tempSave: 'บันทึกชั่วคราว',
    preview: 'ดูตัวอย่าง',
    save: 'โพสต์',
    confirm: 'ยืนยัน',
  },
  postScope: {
    PUBL: 'เผยแพร่สาธารณะ',
    PRIV: 'ดูเฉพาะฉัน',
    // PAID: '유료(en)',
  } as { [Property in ScopeCodeType]: string },
  toastMessage: {
    tempSave: 'บันทึกชั่วคราวเรียบร้อยแล้ว',
    noLinkSelect: 'กรุณาเลือกข้อความที่ต้องการลิงก์',
    havingLink: 'ลิงก์ได้ถูกกำหนดไว้แล้ว',
    validateCategory: 'กรุณาเลือกบอร์ด',
    validateTitle: 'กรุณากรอกชื่อเรื่องอย่างน้อย 2 ตัวอักษร',
    validateContents: 'กรุณากรอกเนื้อหาอย่างน้อย 15 ตัวอักษร',
    validateHashtagCharacter:
      'กรุณากรอกเฉพาะตัวอักษรภาษาเกาหลี, ภาษาอังกฤษ และตัวเลข',
    validateHashtagCheckByte: 'สามารถกรอกได้สูงสุด 30 ตัวอักษร',
    validateHashtagCount: 'สามารถกรอกแท็กได้สูงสุด 5 รายการ',
    validateHashtagDuplicate: 'มีแท็กซ้ำกัน',
    imgError2005: 'นามสกุลไฟล์ที่ไม่สามารถลงทะเบียนได้',
    imgError: 'การอัปโหลดภาพล้มเหลว กรุณาลองอีกครั้งในภายหลัง',
    errors: {
      2011: 'ไม่สามารถเผยแพร่โพสต์พรีเมียมได้ กรุณาตั้งค่า premium ก่อน',
      2015: 'จำนวนแฮชแท็กที่อนุญาตเกินขอบเขต กรุณาลองอีกครั้ง',
      2016: 'จำนวนไฟล์ภาพที่อนุญาตเกินขอบเขต กรุณาลองอีกครั้ง',
      2017: 'จำนวนตัวอักษรในชื่อเรื่องไม่เป็นไปตามข้อกำหนด กรุณาลองอีกครั้ง',
      2018: 'จำนวนตัวอักษรในแฮชแท็กเกินขอบเขต กรุณาลองอีกครั้ง',
      2019: 'ไม่พบไฟล์ภาพชั่วคราว กรุณาลองอีกครั้ง',
      2036: 'ไม่มีสิทธิเผยแพร่โพสต์ในบอร์ดนี้',
      8048: 'ไม่สามารถทำกิจกรรมเดียวกันซ้ำในระยะเวลาที่กำหนด',
      8049: 'เกินจำนวนโพสต์สูงสุดที่สามารถเผยแพร่ได้ในหนึ่งสัปดาห์',
      default: 'เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองอีกครั้งในภายหลัง',
    },
    validateLinkDuplicate: 'มีลิงก์ซ้ำกัน',
    availableLink: 'ลิงก์ที่ใช้ได้',
    nolastFileDelete: 'ไม่สามารถลบไฟล์สุดท้ายได้',
    createPostSuccess: 'เผยแพร่โพสต์ {postTitle} เรียบร้อยแล้ว',
    editPostSuccess: 'แก้ไขโพสต์ {postTitle} เรียบร้อยแล้ว',
    createPostUnavailable: {
      2011: 'บอร์ดที่ไม่รู้จัก',
      2036: 'ไม่มีสิทธิเผยแพร่โพสต์ในบอร์ดนี้',
    },
    videoFileSize: {
      over: 'คุณเกินขนาดไฟล์ที่อนุญาต (500MB)',
      error: 'เกิดข้อผิดพลาดเนื่องจากขนาดไฟล์เกินขีดจำกัด',
      warning: 'การอัปโหลดไฟล์ที่มีขนาดใหญ่กว่า 100MB อาจใช้เวลาสักครู่',
    },
  },
  modal: {
    tempTitle: 'รายการบันทึกชั่วคราว',
    previewTitle: 'ดูตัวอย่าง',
    dialogTitle: 'ยืนยันการลบ',
    dialogContent:
      'หากปิดโดยที่ไม่ได้บันทึกข้อมูล ข้อมูลที่กรอกจะถูกลบไป คุณต้องการลบโดยไม่บันทึกหรือไม่?',
  },
}
