// import { subscribe } from './subscribe'
import type {
  CreatorCommentOrderBy,
  CreatorContentsOrderBy,
  CreatorRewardsOrderBy,
  StatisticsOrderBy,
  MyRecommendPostType,
} from '@store'
import type { CreatorHomeTabType } from '@components/NovaBoxCreatorTopHeader/NovaBoxCreatorTopHeader.types'

/**
 * 크리에이터 홈
 */
export const creatorMyPage = {
  home: {
    desc: 'แนะนำ',
    introductionPlaceholder: 'กรุณาใส่เนื้อหา',
    introductionEmpty: 'ไม่มีเนื้อหาในการแนะนำ',
    introductionModifyFail: 'ไม่สามารถบันทึกข้อมูลการแนะนำได้ กรุณาลองอีกครั้ง',
    introductionModifySuccess: 'ข้อมูลการแนะนำได้ถูกบันทึกแล้ว',

    link: 'ลิงก์',
    linkEmpty: 'ไม่มีลิงก์',
    linkTitlePlaceholder: 'ชื่อเรื่องลิงก์',
    linkUrlPlaceholder: 'เพิ่มลิงก์',
    linkRemoveConfirmTitle: 'ลบลิงก์',
    linkRemoveConfirmMessage: 'คุณต้องการลบลิงก์หรือไม่?',

    linkValidFail: 'กรุณากรอกชื่อเรื่องลิงก์และฟิลด์การเพิ่มลิงก์ให้ครบถ้วน',
    linkAddSuccess: 'ลิงก์ได้ถูกเพิ่มแล้ว',
    linkAddFail: 'ไม่สามารถเพิ่มลิงก์ได้ กรุณาลองอีกครั้ง',
    linkSaveSuccess: 'ลิงก์ได้ถูกบันทึกแล้ว',
    linkSaveFail: 'ไม่สามารถบันทึกลิงก์ได้ กรุณาลองอีกครั้ง',
    linkRemoveSuccess: 'ลิงก์ได้ถูกลบแล้ว',
    linkRemoveFail: 'ไม่สามารถลบลิงก์ได้ กรุณาลองอีกครั้ง',
  },
  profile: {
    title: 'ข้อมูลโปรไฟล์',
    placeholder: {
      nickname: 'กรุณาใส่ชื่อเล่นที่ต้องการใช้',
      content: 'เนื้อหาโปรไฟล์',
    },
    button: {
      cancel: 'ยกเลิก',
      save: 'บันทึก',
    },
    dialog: {
      title: 'การแจ้งเตือน',
      initImage:
        'หากลบภาพ รูปภาพจะเปลี่ยนเป็นอวาตาร์เริ่มต้น คุณต้องการลบหรือไม่?',
      doubleNickname: 'ชื่อเล่นนี้ถูกใช้งานอยู่แล้ว กรุณาใช้ชื่อเล่นอื่น',
      noImage: 'ไม่มีโปรไฟล์ผู้ใช้',
    },
    toastMessage: {
      imgError2005: 'นามสกุลไฟล์ที่ไม่สามารถลงทะเบียนได้',
      imgError: 'การอัปโหลดภาพล้มเหลว กรุณาลองอีกครั้งในภายหลัง',
    },
  },
  tabMenu: {
    contents: 'โพสต์',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // subscribeGrade: subscribe.premiumBrand,
    guestbook: 'สมุดเยี่ยม',
    notice: 'ประกาศ',
    reward: 'รางวัล',
    donation: 'การสนับสนุน',
    activity: 'กิจกรรมของฉัน',
    statistics: 'สถิติ',
    collections: 'คอลเล็กชันของฉัน',
  } as { [Property in CreatorHomeTabType]: string },
  tabsForMyHome: {
    reward: 'รางวัล',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // subscribeGrade: subscribe.premiumBrand,
    contents: 'โพสต์',
    guestbook: 'สมุดเยี่ยม',
    notice: 'ประกาศ',
    donation: 'การสนับสนุน',
    activity: 'กิจกรรมของฉัน',
    statistics: 'สถิติ',
    collections: 'คอลเล็กชันของฉัน',
  } as { [Property in CreatorHomeTabType]: string },
  sort: {
    contents: {
      newest: 'เรียงตามล่าสุด',
      oldest: 'เรียงตามเก่า',
      hot: 'เรียงตามความนิยม',
    } as { [Property in CreatorContentsOrderBy]: string },
    comment: {
      newest: 'เรียงตามล่าสุด',
      oldest: 'เรียงตามเก่า',
      hot: 'เรียงตามความนิยม',
    } as { [Property in CreatorCommentOrderBy]: string },
    reward: {
      newest: 'เรียงตามล่าสุด',
      oldest: 'เรียงตามเก่า',
      rewardQty: 'เรียงตามความนิยม',
    } as { [Property in CreatorRewardsOrderBy]: string },
    stats: {
      D: 'เมื่อวาน',
      W: 'สัปดาห์ที่แล้ว',
      M: 'เดือนที่แล้ว',
    } as { [Property in StatisticsOrderBy]: string },
  },
  panel: {
    reward: {
      title: 'รางวัลทั้งหมด',
      empty: 'ไม่มีจำนวนเงินรางวัล',
    },
    post: {
      public: 'เปิดเผย',
      private: 'ไม่เปิดเผย',
    },
    comment: 'ความคิดเห็นทั้งหมด',
    recommend: {
      get: 'โพสต์ที่ได้รับการแนะนำ',
      set: 'โพสต์ที่แนะนำ',
    } as { [Property in MyRecommendPostType]: string },
    scrap: 'การสแครปทั้งหมด',
  },
  posts: {
    active: 'ประวัติการทำงาน',
    reward: {
      user: 'รางวัลกิจกรรมประจำสัปดาห์ได้ถูกมอบให้แล้ว',
      post: 'รางวัลโพสต์ประจำสัปดาห์ได้ถูกมอบให้แล้ว',
    },
    toastMessage: {
      creatorContentsFilterLessOne:
        'คุณต้องเลือกอย่างน้อยหนึ่งช่องทำเครื่องหมาย',
    },
  },
}
