/**
 * 권한 요청
 */
export const requestAppPermission = {
  requestModal: {
    description: {
      storage: {
        post: "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเขียนโพสต์ กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        profileImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพโปรไฟล์ กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        mainProfileImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพโปรไฟล์หลัก กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        homeHeaderImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพส่วนหัว กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
      },
    },
  },
}
