import { corporationInfo } from './corporationInfo'
import { kyc } from './kyc'
import { profileControl } from './profile-control'
import { donateAction } from './donate-action'
import { creatorGrade } from './creatorGrade'
import { applyCreator } from './applyCreator'
import { commonError } from './commonError'
import { referral } from './referral'
import { creatorHomeLink } from './creatorHomeLink'
import { creatorGuestBook } from './creatorGuestBook'
import { creatorNotice } from './creatorNotice'
import { creatorHome } from './creator'
import { share } from './share'
import { mypage } from './mypage'
import { dialog } from './dialog'
import { userSummery } from './user-summery'
import { creatorMyPage } from './creatorMyPage'
import { home } from './home'
import { navigations } from './navigations'
import { w3cf } from './w3cf'
import { subscribe } from './subscribe'
import { pay } from './pay'
import { accountPanel } from './account-panel'
import { notificationsPanel } from './notificationsPanel'
import { fund } from './fund'
import { timeLimitPopup } from './time-limit-popup'
import { sponsors } from './sponsors'
import { auction } from './auction'
import { terminateApp } from './terminate-app'
import { requestAppPermission } from './request-app-permission'
import { pullToRefresh } from './pullToRefresh'
import { notificationPermission } from './notification-permission'
import { sendbird } from './sendbird'
import { notificationSetting } from './notification-setting'
import { blockMessage } from './block-message'
import { homeActions } from './home-actions'
import { dailyCheck } from './daily-check'
import { blockAccess } from './block-access'
import { postCreate } from './postCreate'
import { serviceBenefits } from './serviceBenefits'
import { rewardPopup } from './rewardPopup'
import { mainTop } from './main-top'
import { aiContents } from './aiContents'
import { menus } from './menus'
import { onboarding } from './onboarding'
import type {
  CommunityMenu,
  FeedMenu,
} from '@components/NovaHeaderMenuPanelMobileView/NovaHeaderMenuPanelMobileView.types'

import type {
  PostsOrderBy,
  PostPrivateAction,
  CommentsAndRepliesOrderBy,
  CommentAndReplyPrivateActionType,
  SearchAllItemType,
  NoticeAction,
} from '@store'

export default {
  message: 'hello',
  confirm: 'Confirm',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  reg: 'Register',
  modify: 'Modify',
  goHome: 'Go home',
  goMyHome: 'Go my home',
  goCreatorHome: 'Go creator home',
  myTotalRewards: 'My total rewards',
  rewardsGate: 'My Rewards',
  useReward: 'Use the rewards',
  kycBeforeUseReward: 'Complete KYC for Rewards',
  helpCenter: '{appName} Help Center',
  attendanceCheck: 'Daily Check-in',
  displayCount: 'Hits',
  category: 'Category',
  allView: 'All',
  sign: {
    in: 'Sign in',
    up: 'Sign up',
    out: 'Sign out',
    off: 'Delete account',
  },
  myRewards: 'My Activities and Rewards',
  linkMyHome: 'My home',
  settings: 'Settings',
  servicesAndPolicies: 'Services & Policies',
  servicesAndPoliciesMenus: {
    servicesMenu: 'Services',
    termsMenu: 'Policies',
  },
  servicesMenu: {
    leisureMetaVerse: 'LEISURE METAVERSE',
    theMoonLabs: 'The Moon Labs',
  },
  service: 'Services',
  services: {
    leisureMetaVerse: 'LEISURE METAVERSE',
    theMoonLabs: 'The Moon Labs',
  },
  profile: 'Profile',
  cs: 'Customer Service',
  accountInfo: 'Edit Profile',
  account: 'Edit Profile',
  defaultSettings: 'Settings',
  notification: 'Notification',
  notifications: 'Notifications',
  notificationAndSetting: 'Notification Settings',
  selectNotificationSound: 'Select Notification Sound',
  language: 'Language',
  languageSettings: 'Language',
  korean: 'KOR',
  english: 'ENG',
  thai: 'THA',
  spanish: 'ESP',
  policy: 'Policies',
  termsAndPolicies: 'Terms & Policies',
  name: 'Name',
  nickName: 'Nick Name',
  email: 'E-Mail',
  referralLabel: 'Referral',
  checkDuplicate: 'Check Duplicate',
  birthday: 'Date of birth',
  simpleIntroduce: 'One-liner Introduction',
  recommenderEmail: 'Referrer E-mail',
  changePassword: {
    title: 'Password',
    change: 'Change',
  },
  referralReg: 'Registration',
  membershipOff: 'Delete account',
  suggestSignUpCopy: 'Challenger to become wealthy of coin!',
  apply: 'Request',
  comments: 'Comments',
  commentsExpand: 'Show the comment',
  commentsCollapse: 'Hide the comment',
  commentStatus: {
    hide: "It's a hidden comment.",
    report: "It's a reported comment.",
    delete: "It's a deleted comment.",
    signOff: "It's a deleted comment.",
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  replies: 'Replies',
  postAction: 'Posting Activities',
  createPost: 'Write',
  editPost: 'Edit',
  createNotice: 'Write',
  modifyNotice: 'Modify',
  viewRelatedPosts: 'Show related posts',
  event: 'Event',
  notice: 'Notice',
  answNotifyDescription:
    'Notifications for comments and activities on your comments',
  evntNotifyDescription: 'Notifications for events',
  ntceNotifyDescription: 'Notifications for announcements',
  postNotifyDescription: 'Notifications for activities on your posts',
  dntnNotifyDescription:
    'General, Notifications for {subscribeGrade} post donation',
  cprpNotifyDescription:
    'Notifications for activities on your {subscribeGrade} posts',
  cppsNotifyDescription: 'Notifications for Supporter of {subscribeGrade}',
  faq: 'FAQ',
  hashtag: 'Hashtag',
  hashtagRanking: 'Trending',
  searchHashtag: 'Search',
  searchPlaceholder: 'Search',
  seeMore: 'More',
  creator: 'Creator',
  linkCreatorHome: 'Creator home',
  creatorHomeLink,
  creatorGuestBook,
  creatorNotice,
  creatorGrade,
  creatorHome,
  follower: 'Follower',
  follow: 'Follow',
  following: 'Following',
  donate: 'Donate',
  donateAction,
  gapTime: {
    underHour: 'Right before',
    underDay: 'hours ago',
    underWeek: 'days ago',
  },
  showTermDetail: 'View',
  agreeAllTerms: 'Full agreement of terms and conditions',
  essentialTerm: '[Required]',
  optionalTerm: '[Optional]',
  rewards: {
    title: 'Daily challenger to receive reward',
    description: 'Accomplish your goal and receive plenty of reward!',
    goRewards: 'View my reward',
    certificationKyc: 'Complete KYC for Rewards',
    useRewards: 'Use the rewards',
    rewardsCopy: {
      article:
        'You have received the reward of average <span class="text-color-primary-blue text-bold">{lm} LM</span> for one text.',
      comment:
        'You have received the reward of average <span class="text-color-primary-blue text-bold">{lm} LM</span> for one comment.',
      post: 'You have received the reward of average <span class="text-color-primary-blue text-bold">{lm} LM</span> for one posting.',
    },
    lastWeekAverageLM:
      '※ Average LM price <span class="text-color-2 text-medium">{price}</span> won for the last week',
  },
  postRewards: {
    expected: 'Expected rewards',
    total: 'All rewards',
  },
  postRecommend: {
    like: 'Like',
    disLike: 'Dislike',
  },
  postPrivateAction: {
    share: 'Share',
    scrap: 'Save',
    cancelScrap: 'Unsave',
    // hide: 'Hide',
    report: 'Report',
    edit: 'Edit',
    remove: 'Delete',
    donate: 'Donate',
  } as { [Property in PostPrivateAction]: string },
  postRemoveConfirmMessage: 'Will you delete your posting?',
  noticeAction: {
    modify: 'Modify',
    remove: 'Delete',
  } as { [Property in NoticeAction]: string },
  noticeRemoveConfirmMessage: 'Will you delete your posting?',
  searchMenu: 'Menu Search',
  feeds: 'Feeds',
  feedsMenu: {
    forYouPost: 'For you posts',
    recentPost: 'Recent posts',
    followingPost: 'Following posts',
  } as { [Property in FeedMenu]: string },
  community: 'Community',
  communityMenus: {
    // best: 'BEST',
    // basicGuide: 'Basic Guide',
    // insight: 'Insight',
    // allTemplate: 'All Templates',
    categories: 'Categories',
  } as { [Property in CommunityMenu]: string },
  etc: 'Others',
  etcMenus: {
    event: 'Event',
    notice: 'Notice',
    faq: 'FAQ',
  },
  report: {
    inappropriate: 'Improper posting (Displeasure or violence)',
    false: 'Fake content',
    spam: 'SPAM',
    etc: 'Etc',
  },
  reportForm: {
    title: 'Report',
    dropdownPlaceholder: 'Report Category',
    textareaPlaceholder: 'Write a content',
  },
  reportAction: {
    cancel: 'Cancel',
    send: 'Report',
  },
  statusMessages: {
    searchKeyword: {
      empty: 'Please insert search keywords',
    },
    search: {
      success: "<strong>'{keyword}'</strong> Search Result",
      empty: '{keyword} No search result exists.',
      error: 'Not available to search {keyword}. Try again later.',
    },
    menus: {
      empty: '{menu} No menu exists',
    },
    postsByHashtagSimple: {
      empty: '{hashtag} No posting exists',
      error: '{hashtag} Not available to search posting. Try again later.',
    },
    categories: {
      empty: 'No categories are available.',
    },
    hashtagRanking: {
      empty: 'No hashtags are available.',
    },
    tempList: {
      empty: 'No list of Temporary Save exists.',
      error: 'Not available to search the list of Temporary Save. Try again.',
    },
    recommendAndUnRecommend: {
      recommend: {
        success: 'Liked',
        error: "It can't be liked. Please try again later.",
        2037: 'Processing failed as you were identified as an automated bot. Please refresh the page and try again.',
        8048: 'The same activity cannot be repeated within a certain amount of time.',
        8050: "You can't like the post I wrote.",
      },
      recommendCancel: 'Unable to cancel the like. Please try again later.',
      unRecommend: "It can't be disliked. Please try again later.",
      unRecommendCancel:
        'Unable to cancel the dislike. Please try again later.',
    },
    postScrap: {
      success: 'Post saved.',
      error: 'Unable to save the post. Please try again later.',
    },
    postScrapCancel: {
      success: 'Post unsaved.',
      error: 'Unable to undo a post save. Please try again later.',
    },
    postHide: {
      error: 'Not available to hide the posting. Try again later',
    },
    postReport: {
      success: 'Posting report has been received normally. ',
      errors: {
        2000: 'Cannot find the post.',
        2006: 'Choose the report category.',
        2010: 'The post is already reported.',
        2012: 'You cannot report yourself.',
        4002: 'It is an unregistered user.',
        2020: 'This post has already been moderated.',
        unknown: 'Cannot report the post. Please try again later.',
      },
    },
    postRemove: {
      error: 'Not available to delete the posting. Try again later',
    },
    tempPostRemove: {
      error:
        "We couldn't delete your draft post right now. Please try again later.",
    },
    mypageList: {
      reward: {
        empty: "You haven't received any rewards yet.",
        error:
          "We couldn't get your reward history at the moment. Please try again later.",
      },
      post: {
        empty: "You haven't created any posts yet.",
        error:
          "We couldn't import your post list right now. Please try again later.",
      },
      comment: {
        empty: "You haven't left any comments on posts yet.",
        error:
          "We couldn't import the posts with comments right now. Please try again later.",
      },
      recommend: {
        get: {
          empty: "You haven't received any likes yet.",
          error:
            "We couldn't import the posts with likes right now. Please try again later.",
        },
        set: {
          empty: "You haven't liked any posts yet.",
          error:
            "We couldn't import the posts you liked right now. Please try again later.",
        },
      },
      scrap: {
        empty: "You haven't saved any posts yet.",
        error:
          "We couldn't import the post list right now. Please try again later.",
      },
    },
    creatorList: {
      guest: {
        empty: 'Scheduled to open later',
      },
      notice: {
        empty: 'Scheduled to open later',
      },
    },
    creators: {
      empty: 'No creator exists.',
      error: "We couldn't show the creator right now. Please try again later.",
    },
    users: {
      empty: 'No user exists.',
      error: "We couldn't show the user right now. Please try again later.",
    },
    creatorFollow: {
      error: 'Not available to follow. Please try again later.',
    },
    creatorUnfollow: {
      error: 'Not available to unfollow. Please try again later.',
    },
    creatorHome: {
      invalidUrl: 'Please check the URL again.',
    },
    recentSearches: {
      empty: 'No recent search word exists',
    },
    postDetail: {
      error: {
        notFound: 'The post has been deleted or cannot be found.',
        drop: 'This is a moderated post.',
        notSubscribed: 'You do not have access to the {subscribeGrade} post.',
        blocked: 'The host blocked you, so you can’t access the post.',
      },
    },
    comments: {
      empty: 'There are no comments.<br /> Please leave the first comment!',
      error: "We couldn't show the comment right now. Please try again later.",
    },
    commentsAdd: {
      empty: '{type} No contents exist',
      errors: {
        8048: 'The same activity cannot be repeated within a certain amount of time.',
        default:
          "We couldn't edit {type} at the moment. Please try again later.",
      },
    },
    commentsEdit: {
      empty: '{type} No contents exist',
      error:
        "We couldn't register {type} at the moment. Please try again later.",
    },
    commentsDelete: {
      denied: 'This comment has already been deleted.',
      error:
        'This comment cannot be deleted at the moment. Please try again later.',
    },
    commentsReport: {
      success: 'The report received successfully.',
      errors: {
        2000: 'Cannot find the comment.',
        2006: 'Choose the report category.',
        2010: 'The comment is already reported.',
        2012: 'You cannot report yourself.',
        2020: 'This comment has already been sanctioned.',
        4002: 'It is an unregistered user.',
        unknown: 'Cannot report the comment. Please try again later.',
      },
    },
    commentsHide: {
      denied: 'This comment is already hidden.',
      error:
        'You cannot hide this comment at the moment. Please try again later.',
    },
    boardList: {
      empty: 'No registered text exists',
      error: 'Not available to import corresponding list. Try again later',
    },
    tableList: {
      empty: 'No data exists',
      error: 'Not available to import corresponding list. Try again later.',
    },
    retire: {
      loading:
        'It takes time for asset search to withdraw membership. Please wait a moment',
      error: 'Not available to import the data. Try again later.',
    },
    notification: {
      empty: 'No notification exists',
      error: 'Not available to import notification list. Try again later',
    },
    network: {
      error: commonError.network.message,
    },
  },
  postCreate,
  noticeCreate: {
    placeholder: {
      public: 'Public',
      tempSave: 'Load Draft',
      title: 'Write a title',
      content: 'Write a content. (Mandatory)',
      hashtag: '#Input_Hashtag',
    },
    button: {
      tempSave: 'Save Draft',
      preview: 'Preview',
      save: 'Save',
      confirm: 'Confirm',
    },
    toastMessage: {
      tempSave: 'It has been saved temporarily.',
      noLinkSelect: 'Please select the text to link.',
      havingLink: 'Link has been already designated.',
      validateTitle: 'Please write more than 2 characters for the title.',
      validateContents:
        'Please write more than 10 characters for the contents.',
      validateHashtagCharacter:
        'It is available to input Korean, English and numbers.',
      validateHashtagCheckByte:
        'It is available to input maximum 30 characters.',
      validateHashtagCount: 'It is available to input maximum 5 tags.',
      validateHashtagDuplicate: 'Duplicated tag exists.',
      imgError2005: 'This extension cannot be registered.',
      imgError: 'The image upload failed. Please try again later.',
      error:
        'An error occurred while processing your request. Please try again later.',
    },
    modal: {
      tempTitle: 'Draft',
      previewTitle: 'Preview',
      dialogTitle: 'Confirm deletion',
      dialogContent:
        'If you close without saving, any content you entered will be lost. Do you want to continue and discard changes?',
    },
  },
  qna: {
    title: 'Customer Inquiry',
    button: {
      cancel: 'Cancel',
      confirm: 'Submit',
    },
    reportMessage: '*The reply will be sent to your account email.',
    placeholder: {
      content: 'Enter the content',
      dropdown: 'Inquiry Category',
    },
    error:
      "We couldn't process your inquiry request at the moment. Please try again later.",
    dialog: {
      title: 'Inquiry',
      success: "Your inquiry has been received. We'll get back to you soon.",
    },
  },
  creatorMyPage,
  mypage,
  board: {
    sideTitle: 'Board',
    title: {
      notice: 'Notice',
      event: 'Event',
      /* news: 'News', */
      faq: 'FAQ',
      terms: 'Services & Policies',
    },
    label: {
      notice: 'Notice',
      list: 'List',
      prev: 'Prev',
      next: 'Next',
      search: 'Search',
      total: 'All',
    },
  },
  retire: {
    title: 'Closing Account Instructions',
    password: {
      title: 'Password',
      content: {
        text: 'You are required to read and agree on the instructions before you leave.',
        warning: 'Are you sure you want to proceed?',
      },
      validate:
        'Confirm your password and log in again. (Login will be blocked for more than 5 times of error)',
    },
    guide: {
      type1: {
        subTitle: 'Please confirm before the withdrawal of your membership!',
        list: [
          'Please be aware that if you choose to leave the I LIKE LM service, you will not be able to re-register for 30 days and any existing balance or digital assets in your LM Wallet account will also be permanently lost.',
        ],
        text: 'Deleting your account also means that all your personal information and transaction history will be removed.',
        warning: 'Are you sure you want to proceed?',
        agree: 'I agree.',
        complete: 'Your account has been deleted',
      },
      type2: {
        content: {
          text1:
            'The deleting process is currently disabled because you have a remaining balance and digital assets in it.',
          text2:
            'If you want to proceed with deleting your account, please reach out to our customer support team at <em class="text bule">cs&#64;likelm.com</em>.',
        },
        button: {
          inquire: 'Contact Us',
          home: 'HOME',
        },
        modal: {
          title: 'Closing Account',
        },
      },
    },
    inquire: {
      text: {
        title: 'Your Inquiry',
        validate: 'Please enter your inquiry here',
        placeholder: 'Please enter your inquiry here',
      },
      email: {
        title: 'Email Address',
        validate: {
          text: 'The response will be sent to this email, so please enter it correctly.',
          error: 'The response will be sent to this email',
        },
      },
      term: {
        title: 'Collection and Use of Personal Information',
        content: {
          text1:
            'playNomm Co. Ltd. processes personal information for the following purposes to handle customer inquiries.',
          text2:
            "For more information, please refer to the LM Noma's <em>Privacy Policy</em>",
        },
        list: [
          {
            title: 'Collection Items',
            detail: 'Email address',
          },
          {
            title: 'Purpose',
            detail: 'Response and handling of customer inquiries',
          },
          {
            title: 'Retention period',
            detail: '2 years since the inquiry',
          },
        ],
        checkbox:
          "I agree to the 'Personal Information Collection and Use Policy'.",
      },
      button: 'SEND',
      modal:
        "Your inquiry has been received. We'll get back to you as soon as possible.",
    },
  },
  dialog,
  sort: {
    'for-you': 'For you',
    newest: 'Newest',
    'follow-newest': 'Following',
  } as { [Property in PostsOrderBy]: string },
  copyToClipboard: 'Link has been copied in the clip board.',
  recentSearches: 'Recent searches',
  seeAllResult: 'See all results',
  searchResultItemType: {
    user: 'User',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // premium: subscribe.premiumBrand,
    post: 'Posts',
    comment: 'Comments',
    hash: 'Hashtags',
  } as { [Property in SearchAllItemType]: string },
  writeComment: 'Write a comment',
  doComment: 'Comment',
  editComment: 'Edit',
  cancelComment: 'Cancel',
  doReply: 'Reply',
  editReply: 'Edit',
  cancelReply: 'Cancel',
  foldComment: 'Fold comments',
  commentsCount: '{count} Comments',
  commentsAndRepliesSort: {
    newest: 'Newest',
    oldest: 'Oldest',
    hot: 'Hottest',
  } as { [Property in CommentsAndRepliesOrderBy]: string },
  recommendOthersCount: '{count} Others',
  commentAndReplyPrivateAction: {
    // hide: 'Hide',
    report: 'Report comment',
    delete: 'Delete',
    edit: 'Edit',
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  videoAddPanel: {
    videoTypeLabel: 'Video',
    urlLabel: 'Link',
    urlPlaceholder: 'Enter the link',
    addBtn: 'Confirm',
    descriptions: {
      youtube: [
        'https://www.youtube.com/watch?v=xxxxxxxxxxx',
        'https://www.youtu.be/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        'Please enter a link in the format of the example address above.',
      ],
      youtubeShorts: [
        'https://www.youtube.com/shorts/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        'Please enter a link in the format of the example address above.',
      ],
      vimeo: [
        'https://vimeo.com/xxxxxxxxx',
        'https://player.vimeo.com/video/xxxxxxxxx',
        'Please enter a link in the format of the example address above.',
      ],
    },
  },
  colorPickerPanel: {
    textColorLabel: 'Text color',
    highlightColorLabel: 'Highlight',
  },
  linkAddPanel: {
    label: 'Link',
    placeholder: 'Enter the link',
    addBtn: 'OK',
  },
  corporationInfo,
  kyc,
  profileControl,
  events: {
    recommender: {
      title: 'Season 2! Open Event',
      recommenders: {
        empty: 'There is no ranking information yet.',
        error:
          'Ranking information cannot be retrieved. Please check again in a while.',
      },
      rank: 'Rank',
      email: 'E-mail Address',
      recommendCount: 'Referrals',
      recommendCountUnit: '',
      myRank: 'My Rank',
      myRankStatus: {
        error:
          'Ranking information cannot be confirmed. Please try again in a few minutes.',
      },
      currentTopRank: 'Rank',
      rankCondition:
        'In the event of a tie, the ranking is determined by reflecting the score <span class="highlight">on the number of posts and comments.</span>',
      guide: 'Guide',
      guideDescription:
        'Winners of the event will be informed by an e-mail, and <span class="highlight">please check the announcement after wards.</span>',
    },
  },
  accountSetting: {
    introduction: {
      placeholder: 'Write a introduction.',
    },
  },
  bottomSheet: {
    title: 'Access more comfortably<br>with a click!',
    button: 'See I LIKE LM in App',
  },
  units: {
    days: 'day(s)',
  },
  required: 'Required',
  optional: 'Optional',
  postReportNotification:
    '*If it is judged as a malicious false report, -30 points of disadvantage will be applied and activities may be sanctioned if the number of false reports accumulates.',
  applyCreator,
  commonError,
  referral,
  share,
  userSummery,
  home,
  navigations,
  w3cf,
  subscribe,
  pay,
  accountPanel,
  notificationsPanel,
  fund,
  timeLimitPopup,
  sponsors,
  auction,
  terminateApp,
  requestAppPermission,
  pullToRefresh,
  notificationPermission,
  sendbird,
  notificationSetting,
  blockMessage,
  homeActions,
  dailyCheck,
  blockAccess,
  serviceBenefits,
  rewardPopup,
  mainTop,
  aiContents,
  menus,
  onboarding,
} as { [key in string]: any }
