export const onboarding = {
  content: {
    step01: {
      title: 'Welcome to I LIKE LM!',
      message:
        'I LIKE LM is a Web3 platform <br />where creators and fandoms can <br />engage, communicate, and earn rewards.',
    },
    step02: {
      title: 'Anyone can <br />become a creator!',
      message:
        'If you have your own ideas, become a creator!<br />Upload content and earn rewards <br />based on the engagement you receive.',
    },
    step03: {
      title: 'All activities<br />can be rewarded!',
      message:
        'Support your favorite creators!<br />You can earn rewards based on your activities, <br />such as leaving comments and likes.',
    },
    step04: {
      title: 'Greater engagement boosts community value!',
      message:
        'The more you grow the fandom community,<br />the more opportunities <br />the creator will have to thrive!',
    },
    step05: {
      title: 'Join us right now!',
      message:
        'Experience a new Web3 platform<br />where creation and communication are rewarded!',
    },
  },
  button: {
    again: "Don't show again",
    skip: 'Skip',
    start: 'Start now',
  },
}
