export const menus = {
  wallet: '지갑',
  notice: '공지사항',
  helpCenter: '헬프센터',
  serviceIntro: '서비스 소개',
  userGuide: '유저 가이드',
  faq: '자주 묻는 질문',
  personalCS: '1:1 문의',
  event: '이벤트',
  eventList: '이벤트 목록',
  dailyCheck: '출석체크',
  creatorFund: '크리에이터 펀드',
  policy: '정책',
  terms: '서비스 이용약관',
  privatePolicy: '개인정보처리방침',
  familySite: '패밀리 사이트',
  theMoonLabs: '더문랩스',
  leisureMeta: '레저메타',
}
